/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'HeroBanner',
        import: () => import('@stories/Widgets/HeroBanner/HeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Testimonials',
        import: () => import('@stories/Widgets/Testimonials/Testimonials'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TestimonialQuoteCarousel',
        import: () => import('@stories/Widgets/TestimonialQuoteCarousel/TestimonialQuoteCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StatusMessage',
        import: () => import('@stories/Widgets/StatusMessage/StatusMessage'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'InPageAnchorNavigation',
        import: () => import('@stories/Widgets/InPageAnchorNavigation/InPageAnchorNavigation'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Split5050Cta',
        import: () => import('@stories/Widgets/Split5050Cta/Split5050Cta'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageTextCTA',
        import: () => import('@stories/Widgets/ImageTextCTA/ImageTextCTA'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GenericIframeEmbed',
        import: () => import('@stories/Widgets/GenericIframeEmbed/GenericIframeEmbed'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentCards',
        import: () => import('@stories/Widgets/ContentCards/ContentCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'WhyChooseIconUSPs',
        import: () => import('@stories/Widgets/WhyChooseIconUSPs/WhyChooseIconUSPs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Breadcrumbs',
        import: () => import('@stories/Widgets/Breadcrumbs/Breadcrumbs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StoriesListing',
        import: () => import('@stories/Widgets/StoriesListing/StoriesListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MeetYourTeam',
        import: () => import('@stories/Widgets/MeetYourTeam/MeetYourTeam'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LocationLookupList',
        import: () => import('@stories/Widgets/LocationLookupList/LocationLookupList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CompressedContent',
        import: () => import('@stories/Widgets/CompressedContent/CompressedContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FAQAccordion',
        import: () => import('@stories/Widgets/FAQAccordion/FAQAccordion'),
        config: {
            hydrate: 'in-view',
        },
    },
];
